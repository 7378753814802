<template>
  <InputBase :class="{ disabled: isDisabled, tf_error: isError }">
    <input
      ref="input"
      v-model="input"
      :data-ellip="dataEllip"
      :class="inputClass"
      :type="'number'"
      :min="min"
      :max="max"
      :placeholder="placeholder"
      :disabled="isDisabled"
      @keyup="onKeyup"
      @keydown="onKeydown"
      @paste="onPaste"
      @focus="onFocus"
      @blur="onBlur"
      @change="onChange"
      @keyup.13="onKeyUpEnter"
    />
    <!-- type="number"  -->
  </InputBase>
</template>

<script>
import InputBase from "@/components/common/input/InputBase";
import { getCanConvertNumber } from "@/utils/numberUtils";
import { ALERT_ACTION } from "@/store/modules/alert/action";

export default {
  name: "InputRate",
  components: {
    InputBase,
  },
  props: {
    dataEllip: String,
    inputClass: {
      type: String,
      default: "align_right",
    },
    value: [String],
    placeholder: String,
    isValid: {
      type: Boolean,
      default: true,
    },
    round: {
      type: Number,
      default: 2,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    maxLength: {
      type: Number,
      default: 5,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    input: {
      get() {
        let ret = this.value;
        if (Number(ret) < this.min) {
          ret = this.value.slice(0, 0);
          this.$emit("update:value", ret);
          if (this.isValid) {
            this.$toast(`${this.min} 미만이 될수 없습니다.`);
            this.blur();
          }
        }
        if (Number(this.value) > this.max) {
          ret = this.value.slice(0, -1);
          this.$emit("update:value", ret);
          if (this.isValid) {
            this.$toast(`${this.max} 이상이 될수 없습니다.`);
            this.blur();
          }
        }

        if (ret.length > this.maxLength) {
          ret = String(ret).slice(0, this.maxLength);
        }

        ret = String(ret);

        return ret;
      },
      set(value) {
        this.$emit("update:value", String(value));
      },
    },
  },

  methods: {
    onPaste(e) {
      const char = e.clipboardData.getData("text/plain");
      if (char[0] === "-") {
        this.$toast(`음수 값은 넣을 수 없습니다.`);
        e.returnValue = false;
      }
    },
    onKeydown(e) {
      const value = e.target.value;
      if (value.length > this.maxLength) {
        e.target.value = String(value).slice(0, this.maxLength);
      }
      if (e.key === "-") {
        this.$toast(`음수 값은 넣을 수 없습니다.`);
        e.returnValue = false;
      }
    },
    onKeyup(e) {},
    blur() {
      // 외부에서 호출 될 메서드.
      this.$refs.input.blur();
    },
    onFocus(e) {
      this.$emit("update:focus", e);
    },
    onBlur(e) {
      this.$emit("update:blur", e);
    },
    onChange(e) {
      this.$emit("onChange", e);
    },
    onKeyUpEnter() {
      this.blur();
    },
  },
};
</script>
