export default class SystemPerformanceItemModal {
  constructor() {
    this.templateName = "";
    this.templateDesc = "";
    this.templateType = "PA";
    this.questList = [];
    this.activeYn = true;
    // this.retryYn = true;
  }

  setInit() {
    this.addQuest();
    this.addOption(0);
  }

  setData(obj) {
    this.templateName = obj.templateName;
    this.templateDesc = obj.templateDesc;
    this.templateType = obj.templateType;
    this.questList = obj.questList;
    this.activeYn = obj.activeYn;
    // this.retryYn = obj.retryYn;
    obj.questList.forEach((quest) => {
      // item
      quest.optionType = quest.optionList[0].optionType;
      if (
        quest.optionList.length === 2 &&
        quest.optionList[0].selection === "예" &&
        quest.optionList[1].selection === "아니요"
      ) {
        quest.optionType = "A";
      }

      delete quest.regDate;
      delete quest.regId;
      delete quest.templateId;

      quest.importance = quest.importance.toString();
      quest.optionList.forEach((option) => {
        option.score = option.score.toString();
      });
    });
  }

  getData() {
    const tmp = _.cloneDeep(this);
    tmp.questList.forEach((quest) => {
      delete quest.optionType;

      quest.importance =
        typeof quest.importance === "string" ? parseInt(quest.importance) : quest.importance;
      quest.optionList.forEach((option) => {
        option.score = typeof option.score === "string" ? parseInt(option.score) : option.score;
      });
    });
    return tmp;
  }

  addQuest() {
    this.questList.push({
      question: "",
      importance: "0",
      questType: this.templateType,
      optionList: [],
      optionType: "S", // FE 처리용 임시코드
    });
  }

  addOption(idx) {
    if (!this.questList[idx]) return;
    this.questList[idx].optionList.push({
      optionType: "S",
      selection: "",
      score: "0",
    });
  }

  getAlertMessage() {
    if (!this.templateName) return "평가 템플릿명을 입력해주세요.";
    if (!this.templateType) return "평가구분을 입력해주세요.";
    if (!this.activeYn && typeof this.activeYn !== "boolean") return "활성화여부를 선택해주세요.";
    // if (!this.retryYn && typeof this.retryYn !== 'boolean' ) return '재평가 여부를 선택해주세요.';

    let tmp = "";
    let importanceSum = 0;

    for (let i = 0; i < this.questList.length; i++) {
      if (!this.questList[i].question) {
        tmp = "항목명을 입력해주세요.";
        break;
      }
      if (!this.questList[i].importance) {
        tmp = "가중치를 입력해주세요.";
        break;
      }
      const importance = parseInt(this.questList[i].importance);
      importanceSum += importance;
      if (importance < 1 || importance > 100) {
        tmp = "가중치는 1부터 100까지 입력 가능합니다.";
        break;
      }
      if (!this.questList[i].question) {
        tmp = "항목명을 입력해주세요.";
        break;
      }

      if (!this.questList[i].optionList || this.questList[i].optionList.length < 1) {
        // 선택항목이 없으면
        tmp = "항목내용을 입력해주세요.";
        break;
      } else {
        // 선택항목이 있으면
        let isBreak = false;
        for (let j = 0; j < this.questList[i].optionList.length; j++) {
          if (
            !this.questList[i].optionList[j].selection &&
            this.questList[i].optionList[j].optionType !== "M"
          ) {
            tmp = "선택항목명을 입력해주세요.";
            isBreak = true;
            break;
          }
          if (!this.questList[i].optionList[j].score) {
            tmp = "점수를 입력해주세요.";
            break;
          }
          const score = parseInt(this.questList[i].optionList[j].score);
          if (score < 0 || score > 100) {
            tmp = "점수는 0부터 100까지 입력 가능합니다.";
            break;
          }
        }

        if (isBreak) {
          break;
        }
      }
    }

    if (tmp) {
      return tmp;
    }

    // if (importanceSum > 100) {
    //   return '배점의 합이 100보다 큽니다.';
    // }
    if (importanceSum < 100) {
      return "각 항목의 최대 배점 합이 100보다 작습니다.";
    }

    return null;
  }
}
