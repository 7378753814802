<template>
  <TableQuest>
    <template v-slot:colgroup>
      <col />
      <col style="width: 100px" />
      <col style="width: 80px" />
    </template>
    <template v-slot:header>
      <tr>
        <!--        <th class="align_center">No</th>-->
        <th class="align_center">선택항목</th>
        <th class="align_center">점수</th>
        <th class="align_center">
          <button
            v-if="quest.optionType === 'S'"
            class="btn_small btn_secondary"
            @click="onClickAddOption(qIdx)"
          >
            추가
          </button>
        </th>
      </tr>
    </template>
    <template v-slot:body>
      <tr v-for="(option, oIdx) in quest.optionList" :option="option" :key="oIdx" :idx="oIdx">
        <td>
          <Input
            :value.sync="option.selection"
            :isDisabled="quest.optionType !== 'S'"
            :maxLength="30"
            :placeholder="placeholder()"
          />
        </td>
        <td class="align_center">
          <InputRate
            :value.sync="option.score"
            :maxLength="3"
            class="inp_score"
            @onChange="(evt) => onChangeScore(evt, qIdx)"
          />
        </td>
        <td class="align_center">
          <button
            v-if="quest.optionType !== 'A' && oIdx > 0"
            class="btn_small btn_fourthly"
            @click="onClickDelOption(oIdx)"
          >
            삭제
          </button>
        </td>
      </tr>
    </template>
  </TableQuest>
</template>
<script>
import TableQuest from "@/pages/admin/system/performance/TableQuest";
import Input from "@/components/common/input/Input";
import InputRate from "@/components/common/input/InputRate";
export default {
  components: { InputRate, TableQuest, Input },
  props: {
    quest: Object,
    qIdx: Number,
  },
  methods: {
    onClickAddOption(val) {
      this.$emit("onClickAddOption", val);
    },
    onClickDelOption(val) {
      this.$emit("onClickDelOption", {
        qIdx: this.qIdx,
        oIdx: val,
      });
    },
    placeholder() {
      return this.quest.optionType === "M" ? "직접입력" : "선택항목명";
    },
    onChangeScore(evt, idx) {
      this.$emit("onChangeScore", evt.target.value, idx);
    },
  },
};
</script>
<style scoped></style>
