<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 class="tit_section">평가 템플릿</h2>
      </div>
      <div class="body_section">
        <TableViewWithTitle title="평가 정보">
          <template v-slot:colgroup>
            <col style="width: 192px" />
            <col style="width: 267px" />
            <col style="width: 192px" />
            <col style="width: 267px" />
          </template>
          <template v-slot:body>
            <tr>
              <th>평가 템플릿명<span class="ico_purchase ico_star">필수항목</span></th>
              <td colspan="3">
                <Input
                  class="w560"
                  :value.sync="model.templateName"
                  :maxLength="30"
                  placeholder="평가 템플릿명을 입력해주세요"
                />
              </td>
            </tr>
            <tr>
              <th>평가구분<span class="ico_purchase ico_star">필수항목</span></th>
              <td colspan="3">
                <Radio
                  :name="templateType.name"
                  :dataList="templateType.dataList"
                  :selectedId.sync="model.templateType"
                  @onChange="onChangeTemplateType"
                />
              </td>
            </tr>
            <tr>
              <th>활성화 여부</th>
              <td colspan="3">
                <Radio
                  :name="activeYn.name"
                  :dataList="activeYn.dataList"
                  :selectedId.sync="model.activeYn"
                />
              </td>
            </tr>
            <!--            <tr>-->
            <!--              <th>재평가</th>-->
            <!--              <td colspan="3">-->
            <!--                <Radio-->
            <!--                  :name="retryYn.name"-->
            <!--                  :dataList="retryYn.dataList"-->
            <!--                  :selectedId.sync="model.retryYn"-->
            <!--                />-->
            <!--              </td>-->
            <!--            </tr>-->
            <tr>
              <th>비고</th>
              <td colspan="3">
                <Textarea :value.sync="model.templateDesc" :maxLength="100" placeholder="비고" />
              </td>
            </tr>
          </template>
        </TableViewWithTitle>

        <TableQuest title="항목 정보">
          <template v-slot:colgroup>
            <col style="width: 428px" />
            <col />
            <col style="width: 80px" />
          </template>
          <template v-slot:header>
            <tr>
              <th>항목명</th>
              <th>항목내용</th>
              <!--              <th>가중치</th>-->
              <th>
                <button class="btn_small btn_secondary" @click="onClickAdd">추가</button>
              </th>
            </tr>
          </template>
          <template v-slot:body>
            <template v-if="model.questList">
              <tr v-for="(quest, qIdx) in model.questList" :key="qIdx">
                <td>
                  <Input
                    class="w405"
                    :value.sync="quest.question"
                    :maxLength="30"
                    placeholder="항목명을 입력해주세요"
                  />
                </td>
                <td>
                  <Radio
                    :name="`${optionType.name}_${qIdx}`"
                    :dataList="optionType.dataList"
                    :selectedId.sync="quest.optionType"
                    @onChange="onChangeOptionType($event, qIdx)"
                  />
                  <PerformanceSelectionList
                    :quest="quest"
                    :qIdx="qIdx"
                    :optionType="quest.optionType"
                    @onClickAddOption="onClickAddOption"
                    @onClickDelOption="onClickDelOption"
                    @onChangeScore="onChangeScore"
                  />
                </td>
                <!--                <td class="align_center">-->
                <!--                  <div class="group_form">-->
                <!--                    <InputRate-->
                <!--                      :value.sync="quest.importance"-->
                <!--                      :maxLength="3"-->
                <!--                      placeholder=""-->
                <!--                      class="w80 inp_importance"-->
                <!--                    />-->
                <!--                    <strong class="txt_view_r">%</strong>-->
                <!--                  </div>-->
                <!--                </td>-->
                <td>
                  <button v-if="qIdx > 0" class="btn_small btn_fourthly" @click="onClickDel(qIdx)">
                    삭제
                  </button>
                </td>
              </tr>
            </template>
          </template>
        </TableQuest>
      </div>
    </div>
    <Sticky>
      <div class="area_left">
        <button type="button" class="btn_fourthly btn_large" @click="onClickCancel">취소</button>
      </div>
      <div class="area_right">
        <button type="submit" class="btn_primary btn_large" @click="onClickAlertConfirm">
          {{ ConfirmLabelText }}
        </button>
      </div>
    </Sticky>
    <template v-slot:popup>
      <AlertPopup
        v-if="isAlertConfirm"
        :alertText="regAlertText"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCloseAlertConfirm"
        @onClickPrimary="onClickConfirm"
      />

      <AlertPopup
        v-if="isAlertCancel"
        alertText="평가 템플릿 등록을 취소하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickAlertCancel"
        @onClickPrimary="onClickAlertOk"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import Popup from "@/components/layout/popup/Popup";
import PageWithLayout from "@/components/layout/PageWithLayout";
import TableViewWithTitle from "@/components/shared/tableView/TableViewWithTitle";
import Radio from "@/components/common/radio/Radio";
import Input from "@/components/common/input/Input";
import Textarea from "@/components/common/textarea/Textarea";
import TableHead from "@/components/shared/TableHead";
import Sticky from "@/components/layout/content/Sticky";
import AlertPopup from "@/components/layout/popup/AlertPopup";
import PageMixin from "@/mixins/PageMixin";
import ApiService from "@/services/ApiService";

import SystemPerformanceItemModal from "@/pages/admin/system/performance/SystemPerformanceItemModal";
import TableQuest from "@/pages/admin/system/performance/TableQuest";
import InputNumber from "@/_approval/components/common/input/InputNumber";
import InputRate from "@/components/common/input/InputRate";
import PerformanceSelectionList from "@/components/admin/system/performance/PerformanceSelectionList";
import { PROGRESS_END_ACTION } from "@/store/modules/progressbar/action";

export default {
  name: "PerformanceWrite",
  components: {
    PerformanceSelectionList,
    InputRate,
    InputNumber,
    TableQuest,
    PageWithLayout,
    Popup,
    Radio,
    Input,
    Textarea,
    TableViewWithTitle,
    TableHead,
    Sticky,
    AlertPopup,
  },
  mixins: [PageMixin],
  data() {
    return {
      itemId: null,
      isModifyMode: false,
      model: new SystemPerformanceItemModal(),
      isAlertCancel: false,
      isAlertConfirm: false,
      rawObj: {},

      templateType: {
        name: "templateType",
        dataList: [
          { id: "PA", text: "파트너평가" },
          { id: "PE", text: "수행도평가" },
        ],
      },
      optionType: {
        name: "optionType",
        dataList: [
          { id: "S", text: "선택형" },
          { id: "A", text: "단답형" },
          { id: "M", text: "서술형" },
        ],
      },
      activeYn: {
        name: "activeYn",
        dataList: [
          { id: true, text: "활성화" },
          { id: false, text: "비활성화" },
        ],
      },
      // retryYn: {
      //   name: 'retryYn',
      //   dataList: [
      //     { id: true, text: '사용' },
      //     { id: false, text: '사용안함' },
      //   ],
      // },
    };
  },
  computed: {
    // 등록 컨펌 메시지
    regAlertText() {
      if (this.isModifyMode) {
        return "평가 템플릿을 수정하시겠습니까?";
      }
      return "평가 템플릿을 등록하시겠습니까?";
    },
    // 등록 버튼
    ConfirmLabelText() {
      if (this.isModifyMode) {
        return "수정";
      }
      return "등록";
    },
  },
  beforeMount() {
    this.isModifyMode = this.$route.meta.isModifyMode;

    if (this.isModifyMode) {
      this.itemId = this.$route.params.id;
      this.getData();
    } else {
      this.model.setInit();
    }
  },
  methods: {
    async getData() {
      const path = `${this.$apiPath.ST_EVALUATE}/${this.itemId}`;
      const result = await ApiService.shared.getData(path);

      this.$store.dispatch(PROGRESS_END_ACTION);

      if (!result.data) return;

      this.rawObj = result.data;
      this.model.setData(result.data);
    },
    // 등록 컨펌 > 승인
    async onClickConfirm() {
      const objData = this.model.getData();

      const path = `${this.$apiPath.ST_EVALUATE}`;
      if (!this.isModifyMode) {
        // 등록
        const result = await ApiService.shared.postData(path, objData);

        if (result.code !== "200") {
          this.alert(result.text);
          return;
        }
      } else {
        // 수정
        const result = await ApiService.shared.putData(`${path}/${this.itemId}`, objData);

        if (result.code !== "200") {
          this.alert(result.text);
          return;
        }
      }
      // 목록으로 이동
      this.onClickAlertOk();
    },
    // 취소 버튼
    onClickCancel() {
      this.isAlertCancel = true;
    },
    // 등록 취소 컨펌 > 확인
    onClickAlertOk() {
      if (this.isModifyMode) {
        this.$router.push({
          path: `${this.$routerPath.SYSTEM_PERFORMANCE_SETTING}/${this.itemId}`,
        });
      } else {
        this.$router.push({
          path: this.$routerPath.SYSTEM_PERFORMANCE_SETTING,
        });
      }
    },
    // 등록 취소 컨펌 > 취소
    onClickAlertCancel() {
      this.isAlertCancel = false;
    },
    // 등록 버튼
    onClickAlertConfirm() {
      const alertMessage = this.model.getAlertMessage();
      if (alertMessage) {
        this.alert(alertMessage);
        return;
      }
      this.isAlertConfirm = true;
    },
    // 등록 컨펌 > 취소
    onClickCloseAlertConfirm() {
      this.isAlertConfirm = false;
    },
    // 문항 행 추가
    onClickAdd() {
      this.model.addQuest();
      this.model.addOption(this.model.questList.length - 1);
    },
    // 문항 행 삭제
    onClickDel(qIdx) {
      this.model.questList.splice(qIdx, 1);
    },
    // 선택항목 행 추가
    onClickAddOption(qIdx) {
      this.model.addOption(qIdx);
    },
    // 선택항목 행 삭제
    onClickDelOption(val) {
      const { qIdx, oIdx } = val;
      this.model.questList[qIdx].optionList.splice(oIdx, 1);
    },
    // 평가구분 onChange
    onChangeTemplateType(val) {
      this.model.questList.forEach((item) => {
        item.questType = val;
      });
    },
    // 문항 타입 onChange
    // M 주관식 S 객관식 A 단답형
    onChangeOptionType(type, qIdx) {
      if (type === "M") {
        this.model.questList[qIdx].optionList = [
          {
            optionType: "M",
            selection: "",
            score: "0",
          },
        ];
      } else if (type === "S") {
        this.model.questList[qIdx].optionList = [
          {
            optionType: "S",
            selection: "",
            score: "0",
          },
        ];
      } else if (type === "A") {
        this.model.questList[qIdx].optionList = [
          {
            optionType: "S",
            selection: "예",
            score: "0",
          },
          {
            optionType: "S",
            selection: "아니요",
            score: "0",
          },
        ];
      }
    },
    // 선택항목 점수 변경 감지 onChange
    onChangeScore(val, qIdx) {
      const item = this.model.questList[qIdx];
      const importance = item.importance ? parseInt(item.importance) : 0;
      let tmp = 0;
      item.optionList.forEach((option) => {
        if (tmp < parseInt(option.score)) {
          tmp = option.score;
        }
      });
      item.importance = tmp;
    },
  },
};
</script>
<style scoped>
.inp_importance::v-deep input {
  text-align: right;
}
</style>
